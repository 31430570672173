<!--列表页面-->
<template>
  <div class="center">
    <el-container>
      <wf-category @node-click="nodeClick"
                   :parentId="parentId"
                   @list-change="findObject(option.column, 'category').dicData = $event"></wf-category>
      <el-main>
        <!--列表label-->
        <head-layout :head-title="this.$t('cip.plugin.workflow.process.title.indexHeadTitle')"></head-layout>
        <!--列表查询条件和头部按钮-->
        <grid-head-layout ref="gridHeadLayout"
                          :search-columns="searchColumns"
                          @grid-head-search="gridHeadSearch"
                          @grid-head-empty="gridHeadEmpty"></grid-head-layout>
        <!--列表展示-->
        <grid-layout ref="gridLayOut"
                     :table-options="option"
                     :table-data="data"
                     :table-loading="loading"
                     :data-total="page.total"
                     :grid-row-btn="gridRowBtn"
                     @page-current-change="onLoad"
                     @page-size-change="onLoad"
                     @page-refresh-change="onLoad"
                     @row-edit="rowEdit"></grid-layout>
      </el-main>
    </el-container>

    <!--  <basic-container>
    <el-container>
      <el-aside width="200px">
        <wf-category @node-click="nodeClick"
                     @list-change="findObject(option.column, 'category').dicData = $event"></wf-category>
      </el-aside>
      <el-main style="margin-left: 10px;">
        <template v-if="mode == 'list'">
          <avue-crud :class="['animated fadeIn']"
                     :option="option"
                     :table-loading="loading"
                     :data="data"
                     :page.sync="page"
                     :permission="permissionList"
                     v-model="form"
                     @search-change="searchChange"
                     @search-reset="searchReset"
                     @selection-change="selectionChange"
                     @current-change="currentChange"
                     @size-change="sizeChange"
                     @refresh-change="onLoad(page, query)">
            <template #menuLeft
                      v-if="isDev">
              <el-tag type="warning"
                      effect="dark"
                      size="medium"><i class="el-icon-warning"></i> 部署的流程不显示？请查看使用文档或到 模型设计 中配置权限。
              </el-tag>
            </template>
            <template #menuRight>
              <el-button size="mini"
                         circle
                         icon="el-icon-menu"
                         @click="handleChangeMode('grid')"></el-button>
            </template>
            <template slot="menu"
                      slot-scope="{row}">
              <el-button v-if="permission.wf_process_start_flow"
                         type="text"
                         size="small"
                         icon="el-icon-video-play"
                         @click="dynamicRoute(row, 'start')">发起</el-button>
            </template>
          </avue-crud>
        </template>
        <wf-start-grid v-else-if="mode == 'grid'"
                       :data="data"
                       :form="query"
                       @route="dynamicRoute($event, 'start')"
                       @mode="handleChangeMode"
                       @search="searchChange"
                       @reset="searchReset"></wf-start-grid>
      </el-main>
    </el-container>
  </basic-container>-->
  </div>
</template>

<script>
import { processList as getList } from "@/api/plugin/workflow/process";
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import { mapGetters } from "vuex";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import exForm from '../mixins/ex-form'
import WfStartGrid from './components/start/grid.vue'
import WfCategory from './components/dispatchCategory'

export default {
  mixins: [exForm],
  components: { WfStartGrid, WfCategory, HeadLayout, GridLayout },
  data () {
    return {
      parentId:'1622882715190018050',
      mode: 'list',
      form: {},
      query: {},
      loading: true,
      page: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        column: [
          {
            label: this.$t('cip.plugin.workflow.process.field.icon'),
            prop: "icon",
            type: 'upload',
            width: 80
          },
          {
            label: this.$t('cip.plugin.workflow.process.field.name'),
            prop: "name",
            overHidden: true,
          },
          {
            label: this.$t('cip.plugin.workflow.process.field.key'),
            prop: "key",
            overHidden: true,
          },
          {
            label: this.$t('cip.plugin.workflow.process.field.category'),
            row: true,
            type: 'tree',
            align:'center',
            dicData: [],
            props: {
              label: 'name',
              value: 'id'
            },
            prop: "category",
          },
          {
            label: this.$t('cip.plugin.workflow.process.field.version'),
            prop: 'version',
            align:'right',
            width: 90
          },
          {
            label: this.$t('cip.plugin.workflow.process.field.status'),
            prop: 'status',
            align:'center',
            // dicData: [{
            //   label: this.$t('cip.plugin.workflow.process.field.activation'),
            //   value: 1
            // }, {
            //   label: this.$t('cip.plugin.workflow.process.field.pending'),
            //   value: 2
            // }],
            dataType: 'number',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=activate_pending",
          },
        ]
      },
      data: [],
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList () {
      return {
        addBtn: this.vaildData(this.permission.deployment_add, false),
        viewBtn: this.vaildData(this.permission.deployment_view, false),
        delBtn: this.vaildData(this.permission.deployment_delete, false),
        editBtn: this.vaildData(this.permission.deployment_edit, false)
      };
    },
    ids () {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    isDev () {
      return process.env.NODE_ENV === "development"
    },
    searchColumns () {
      return [
        {
          label: this.$t('cip.plugin.workflow.process.field.name'),
          prop: "name",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t('cip.cmn.rule.inputWarning')+this.$t('cip.plugin.workflow.process.field.name'),
        },
        {
          label: this.$t('cip.plugin.workflow.process.field.icon'),
          prop: "key",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t('cip.cmn.rule.inputWarning')+this.$t('cip.plugin.workflow.process.field.icon'),
        },
      ]
    },
    // 行按钮添加 add by steve
    gridRowBtn () {
      let buttonBtn = [];
      if (this.permission.quality_scheduling_initiate) {
        buttonBtn.push(
          {
            label: this.$t('cip.cmn.btn.initiateBtn'),
            emit: "row-edit",
            type: "primary",
            icon: "el-icon-plus",
          });
      }
      //新增按钮
      return buttonBtn;
    },
  },
  created () {
    //this.handleChangeMode(localStorage.getItem("wf-start-mode") || 'list')
  },
  methods: {
    getTopId(data){
      this.topId = data
    },
    // 列表查询 add by steve
    gridHeadSearch (searchForm) {
      this.onLoad({ pageSize: 10, currentPage: 1 }, searchForm);
    },
    // 查询重置 add by steve
    gridHeadEmpty (searchForm) {
      this.onLoad({ pageSize: 10, currentPage: 1 }, searchForm);
    },
    rowEdit (row) {
      this.dynamicRoute(row, 'start')
    },
    handleChangeMode (mode) {
      localStorage.setItem("wf-start-mode", mode)
      this.mode = mode
      if (this.mode == 'grid') this.page.pageSize = 9999
      else this.page.pageSize = 10
      this.onLoad(this.page, this.query)
    },

    nodeClick ({ id }) {
      this.categoryId = id
      this.searchChange(this.query)
    },
    searchReset () {
      this.query = {};
      this.onLoad(this.page);
    },
    searchChange (params, done) {
      this.query = params;
      this.onLoad(this.page, params);
      if (done && typeof done == 'function') done()
    },
    selectionChange (list) {
      this.selectionList = list;
    },
    currentChange (currentPage) {
      this.page.currentPage = currentPage;
      this.onLoad(this.page, this.query)
    },
    sizeChange (pageSize) {
      this.page.pageSize = pageSize;
      this.onLoad(this.page, this.query)
    },
    onLoad (page, params = {}) {
      this.loading = true;

      if (this.categoryId) params['category'] = this.categoryId
      else  params['category'] = this.parentId

      getList(page.currentPage, page.pageSize, Object.assign(params, this.$refs.gridHeadLayout.searchForm)).then(res => {
        const data = res.data.data;
        this.page.total = data.total;
        this.data = data.records;
        this.loading = false;
      });
    }
  },
  // 初始化数据 add by steve
  mounted () {
    this.onLoad({ pageSize: 10, currentPage: 1 }, {});
  },
};
</script>

<style lang="scss" scoped>
.center {
  padding: 0 !important;
}
::v-deep .avue-crud__img {
  img {
    width: 32px;
    height: 32px;
  }
}
</style>
