var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "100%", "background-color": "#ffffff" } },
    [
      _c("head-layout", {
        attrs: {
          "head-title": "任务基本信息",
          "head-btn-options": _vm.headBtnOptions,
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave(false)
          },
          "head-saveBack": function ($event) {
            return _vm.headSave(true)
          },
          "head-forward": function ($event) {
            return _vm.headForward("forward")
          },
          "head-cancel": _vm.headCancel,
        },
      }),
      _c("div", { staticClass: "formContentBox" }, [
        _c(
          "div",
          { staticClass: "formMain", staticStyle: { padding: "0 12px" } },
          [
            _c(
              "el-form",
              {
                ref: "dataForm",
                attrs: {
                  model: _vm.dataForm,
                  "label-width": "150px",
                  disabled: _vm.formType == "view",
                },
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "任务编号", prop: "actCode" } },
                          [
                            _c(
                              "el-input",
                              {
                                attrs: {
                                  disabled:
                                    _vm.dataForm.isAutomatic ||
                                    _vm.pageDisabled ||
                                    _vm.dataForm.id,
                                  placeholder: "请输入任务编号",
                                },
                                model: {
                                  value: _vm.dataForm.taskCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "taskCode", $$v)
                                  },
                                  expression: "dataForm.taskCode",
                                },
                              },
                              [
                                _c(
                                  "template",
                                  { slot: "append" },
                                  [
                                    _vm._v(
                                      "\n                  自动生成\n                  "
                                    ),
                                    _c("el-switch", {
                                      attrs: {
                                        disabled:
                                          _vm.pageDisabled || _vm.dataForm.id,
                                        "active-color": "#13ce66",
                                      },
                                      on: { change: _vm.handleSwitch },
                                      model: {
                                        value: _vm.dataForm.isAutomatic,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataForm,
                                            "isAutomatic",
                                            $$v
                                          )
                                        },
                                        expression: "dataForm.isAutomatic",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "检查名称", prop: "actName" } },
                          [
                            _c("el-input", {
                              attrs: {
                                title: _vm.dataForm.taskName,
                                placeholder: "请输入检查名称",
                              },
                              model: {
                                value: _vm.dataForm.taskName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "taskName", $$v)
                                },
                                expression: "dataForm.taskName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "检查类型", prop: "checkType" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择检查类型" },
                                model: {
                                  value: _vm.dataForm.checkType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "checkType", $$v)
                                  },
                                  expression: "dataForm.checkType",
                                },
                              },
                              _vm._l(_vm.safey_check_type, function (item) {
                                return _c("el-option", {
                                  key: item.dictKey,
                                  attrs: {
                                    label: item.dictValue,
                                    value: item.dictKey,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "发起人", prop: "startUserName" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请填写发起人" },
                              on: {
                                focus: function ($event) {
                                  return _vm.headAdd1("startUserName")
                                },
                              },
                              model: {
                                value: _vm.dataForm.startUserName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "startUserName", $$v)
                                },
                                expression: "dataForm.startUserName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "所属项目",
                              prop: "organizationName",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                title: _vm.dataForm.organizationName,
                                placeholder: "请填写所属项目",
                              },
                              on: {
                                focus: function ($event) {
                                  return _vm.handleProjectOPen()
                                },
                              },
                              model: {
                                value: _vm.dataForm.organizationName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.dataForm,
                                    "organizationName",
                                    $$v
                                  )
                                },
                                expression: "dataForm.organizationName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "检查日期", prop: "checkTime" } },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                type: "datetime",
                                "value-format": "yyyy-MM-dd HH:mm:ss",
                                placeholder: "请选择检查日期",
                              },
                              model: {
                                value: _vm.dataForm.checkTime,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "checkTime", $$v)
                                },
                                expression: "dataForm.checkTime",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "备注", prop: "remark" } },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "textarea",
                                autosize: { minRows: 2 },
                                "show-word-limit": "",
                                placeholder: "请填写备注",
                              },
                              model: {
                                value: _vm.dataForm.remark,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "remark", $$v)
                                },
                                expression: "dataForm.remark",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.dataForm.id
              ? _c(
                  "div",
                  { staticClass: "shuttleBackBox" },
                  [
                    _c(
                      "el-tabs",
                      {
                        on: { "tab-click": _vm.handleClick },
                        model: {
                          value: _vm.activeName,
                          callback: function ($$v) {
                            _vm.activeName = $$v
                          },
                          expression: "activeName",
                        },
                      },
                      [
                        _vm.formType != "add" &&
                        (_vm.dataForm.addFrom == 0 || _vm.dataForm.addFrom == 3)
                          ? _c("el-tab-pane", {
                              attrs: { label: "检查项", name: "corePersonnel" },
                            })
                          : _vm._e(),
                        _vm.formType != "add" && _vm.dataForm.addFrom == 0
                          ? _c("el-tab-pane", {
                              attrs: {
                                label: "相关资料 ",
                                name: "thirdPartyPersonnel",
                              },
                            })
                          : _vm._e(),
                        _c("el-tab-pane", {
                          attrs: { label: "问题清单", name: "taskList" },
                        }),
                      ],
                      1
                    ),
                    _vm.activeName == "corePersonnel" && _vm.formType != "add"
                      ? _c(
                          "el-container",
                          { staticClass: "main-box" },
                          [
                            _c("CommonTree", {
                              ref: "commonTreeCore",
                              attrs: {
                                treeTitle: "检查项分类",
                                isLeafIcons: "",
                                "node-key": "id",
                                iconsFlied: "isObject",
                                defaultExpandedKeys: _vm.defaultExpandedKeys,
                                searchTitle: "typeName",
                                treeData: _vm.corePersonnelTreeData,
                                defaultProps: {
                                  children: "children",
                                  label: "typeName",
                                },
                                showCheckbox: false,
                                treeExpand: false,
                              },
                              on: {
                                getNodeClick: _vm.corePersonnelTreeNodeClick,
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "table-box" },
                              [
                                _c("head-layout", {
                                  attrs: { "head-title": "检查项" },
                                }),
                                _c("grid-layout", {
                                  ref: "gridLayOut1",
                                  attrs: {
                                    "table-options":
                                      _vm.corePersonnelTableOption,
                                    "data-total": _vm.page.total,
                                    page: _vm.page,
                                    "cell-style": _vm.cellStyle,
                                    "table-data": _vm.corePersonnelTableData,
                                    "table-loading": _vm.jcxTableLoading,
                                  },
                                  on: {
                                    "page-current-change": _vm.getJcx,
                                    "page-size-change": _vm.getJcx,
                                    "gird-handle-select-click":
                                      _vm.selectionChange,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "customBtn",
                                        fn: function ({ row }) {
                                          return [
                                            _vm.formType != "view"
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    staticStyle: {
                                                      margin: "0 3px",
                                                    },
                                                    attrs: {
                                                      type: "text",
                                                      size: "small",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.zxjc({
                                                          ...row,
                                                          disabled: false,
                                                        })
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        row.lineStatus ==
                                                          "PREPARE"
                                                          ? "执行检查"
                                                          : "执行检查"
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.formType == "view"
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    staticStyle: {
                                                      margin: "0 3px",
                                                    },
                                                    attrs: {
                                                      type: "text",
                                                      size: "small",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.zxjc({
                                                          ...row,
                                                          disabled: true,
                                                        })
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("检查结果")]
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    978271704
                                  ),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.activeName == "thirdPartyPersonnel"
                      ? _c(
                          "div",
                          [
                            _c("head-layout", {
                              attrs: { "head-title": "资料清单" },
                            }),
                            _c("el-upload", {
                              staticClass: "upload-demo",
                              attrs: {
                                disabled: "",
                                "on-preview": _vm.handlePreview,
                                "file-list": _vm.xgzlFileList,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.activeName == "taskList" && _vm.dataForm.id
                      ? _c(
                          "div",
                          [
                            _c("head-layout", {
                              attrs: {
                                "head-title": "问题清单",
                                "head-btn-options":
                                  _vm.formType != "view"
                                    ? _vm.tabsHeadBtnOptions
                                    : [],
                              },
                              on: {
                                "head-remove": _vm.headRomve,
                                "head-add": _vm.headAdd,
                              },
                            }),
                            _c("grid-layout", {
                              ref: "gridLayOut",
                              attrs: {
                                "table-options": _vm.hdchecktaskproOption,
                                "table-data": _vm.hdchecktaskproData,
                                "table-loading": _vm.tableLoading,
                              },
                              on: {
                                "grid-row-detail-click": _vm.rowView,
                                "gird-handle-select-click":
                                  _vm.selectionChange1,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "customBtn",
                                    fn: function ({ row }) {
                                      return [
                                        row.hdStatus == "prepare" &&
                                        _vm.userInfo.user_id ==
                                          row.createUser &&
                                        _vm.dataForm.addFrom == "0"
                                          ? _c(
                                              "el-button",
                                              {
                                                staticStyle: {
                                                  margin: "0 3px",
                                                },
                                                attrs: {
                                                  type: "text",
                                                  size: "small",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.rowEdit(row)
                                                  },
                                                },
                                              },
                                              [_vm._v("编辑")]
                                            )
                                          : _vm._e(),
                                        row.hdStatus == "prepare" &&
                                        _vm.userInfo.user_id ==
                                          row.createUser &&
                                        _vm.dataForm.addFrom == "0"
                                          ? _c(
                                              "el-button",
                                              {
                                                staticStyle: {
                                                  margin: "0 3px",
                                                },
                                                attrs: {
                                                  type: "text",
                                                  size: "small",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.rowDel([row])
                                                  },
                                                },
                                              },
                                              [_vm._v("删除")]
                                            )
                                          : _vm._e(),
                                        row.hdStatus != "prepare"
                                          ? _c(
                                              "el-button",
                                              {
                                                staticStyle: {
                                                  margin: "0 3px",
                                                },
                                                attrs: {
                                                  type: "text",
                                                  size: "small",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.rowView(row)
                                                  },
                                                },
                                              },
                                              [_vm._v("查看")]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                4151516620
                              ),
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm.zxhcModel
        ? _c(
            "el-dialog",
            {
              directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
              staticClass: "avue-dialog avue-dialog--top",
              attrs: {
                title:
                  _vm.modelForm.lineStatus != "PREPARE"
                    ? "检查项查看"
                    : "执行检查",
                visible: _vm.zxhcModel,
                width: "60%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.zxhcModel = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "modelFormDataForm",
                  attrs: {
                    model: _vm.modelForm,
                    "label-width": "150px",
                    disabled: _vm.modelForm.disabled,
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "检查结果", prop: "lineStatus" },
                            },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "NORMAL" },
                                  on: { input: _vm.handleInput },
                                  model: {
                                    value: _vm.modelForm.lineStatus,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.modelForm, "lineStatus", $$v)
                                    },
                                    expression: "modelForm.lineStatus",
                                  },
                                },
                                [_vm._v("正常")]
                              ),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "ERROR" },
                                  on: { input: _vm.handleInput },
                                  model: {
                                    value: _vm.modelForm.lineStatus,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.modelForm, "lineStatus", $$v)
                                    },
                                    expression: "modelForm.lineStatus",
                                  },
                                },
                                [_vm._v("异常")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "检查地点", prop: "prjName" } },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: { placeholder: "请填写检查地点" },
                                  model: {
                                    value: _vm.modelForm.position,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.modelForm, "position", $$v)
                                    },
                                    expression: "modelForm.position",
                                  },
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _c("div", {
                                      staticClass: "el-icon-map-location",
                                      staticStyle: {
                                        width: "54px",
                                        "text-align": "center",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openMap()
                                        },
                                      },
                                    }),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "检查内容", prop: "indexName" } },
                            [
                              _c(
                                "div",
                                { staticClass: "flex-container" },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      autosize: { minRows: 2 },
                                      maxlength: "500",
                                      "show-word-limit": "",
                                      placeholder: "请输入检查内容",
                                    },
                                    model: {
                                      value: _vm.modelForm.indexName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.modelForm,
                                          "indexName",
                                          $$v
                                        )
                                      },
                                      expression: "modelForm.indexName",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "hdDesc-mar" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDesc()
                                            },
                                          },
                                        },
                                        [_vm._v("常见隐患")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm.modelForm.lineStatus == "ERROR"
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "项目区域", prop: "area" } },
                                [
                                  _c("el-input", {
                                    ref: "areaName",
                                    attrs: {
                                      placeholder: "请选择项目区域",
                                      title: _vm.modelForm.area,
                                    },
                                    on: { focus: _vm.handleChangeArea },
                                    model: {
                                      value: _vm.modelForm.area,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.modelForm, "area", $$v)
                                      },
                                      expression: "modelForm.area",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.modelForm.lineStatus == "ERROR"
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "隐患接收人",
                                    prop: "assessmentUser",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    ref: "assessmentUserName",
                                    attrs: {
                                      disabled: "",
                                      title: _vm.modelForm.assessmentUserName,
                                    },
                                    model: {
                                      value: _vm.modelForm.assessmentUserName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.modelForm,
                                          "assessmentUserName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "modelForm.assessmentUserName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.modelForm.lineStatus == "ERROR"
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "隐患编号", prop: "hdCode" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      disabled: "",
                                      placeholder: "隐患编号自动生成",
                                    },
                                    model: {
                                      value: _vm.modelForm.hdCode,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.modelForm, "hdCode", $$v)
                                      },
                                      expression: "modelForm.hdCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _vm.modelForm.lineStatus == "ERROR"
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: { label: "隐患描述", prop: "hdDesc" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      autosize: { minRows: 2 },
                                      "show-word-limit": "",
                                      maxlength: "225",
                                      placeholder: "请填写隐患描述",
                                    },
                                    model: {
                                      value: _vm.modelForm.hdDesc,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.modelForm, "hdDesc", $$v)
                                      },
                                      expression: "modelForm.hdDesc",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label:
                                  _vm.modelForm.lineStatus == "ERROR"
                                    ? "隐患情况"
                                    : "现场情况",
                                prop: "prjAs",
                              },
                            },
                            [
                              _vm.formType != "view"
                                ? _c(
                                    "el-upload",
                                    {
                                      ref: "uploadRef",
                                      staticClass: "upload-demo",
                                      staticStyle: {
                                        "margin-bottom": "12px",
                                        display: "flex",
                                        "align-items": "center",
                                      },
                                      attrs: {
                                        accept: ".jpg,.mp4,.png",
                                        action:
                                          "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz-thumbnail/km",
                                        "show-file-list": false,
                                        "before-upload": _vm.checkFileType,
                                        "on-success": _vm.handleAvatarSuccess,
                                        headers: _vm.headers,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "primary",
                                            icon: "el-icon-upload",
                                          },
                                        },
                                        [_vm._v("点击上传\n              ")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "content" },
                                _vm._l(_vm.treeData, function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: item.id,
                                      staticClass: "img-content",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handlePreview(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: item.thumbnailLink,
                                          alt: "",
                                        },
                                      }),
                                      _vm.formType !== "view"
                                        ? _c("i", {
                                            staticClass:
                                              "el-icon-circle-close delete-icon",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.handleRemove(
                                                  item,
                                                  index
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "avue-dialog__footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        loading: _vm.handleBtn,
                        disabled: _vm.handleBtn,
                      },
                      on: {
                        click: function ($event) {
                          _vm.zxhcModel = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  !_vm.modelForm.disabled
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            loading: _vm.handleBtn,
                            disabled: _vm.handleBtn,
                            type: "primary",
                          },
                          on: { click: _vm.handleZxhc },
                        },
                        [_vm._v("确 定")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择位置",
            "append-to-body": "",
            visible: _vm.mapBox,
          },
          on: {
            "update:visible": function ($event) {
              _vm.mapBox = $event
            },
          },
        },
        [
          _vm.mapBox
            ? _c("GaodeMap", {
                ref: "GaodeMap",
                on: {
                  selectAddress: _vm.selectAddress,
                  getLocation: _vm.getLocation,
                },
              })
            : _vm._e(),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.mapBox = false
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) +
                      "\n      "
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleMapSave },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "问题新增",
            "append-to-body": "",
            visible: _vm.hdBox,
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.hdBox = $event
            },
          },
        },
        [
          _c("avue-form", {
            attrs: {
              option: {
                emptyText: "取消",
                column: [
                  {
                    label: "类型",
                    prop: "proType",
                    span: 24,
                    type: "radio",
                    rules: [
                      {
                        required: true,
                        message: "请选择类型",
                        trigger: "blur",
                      },
                    ],
                    dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=hd_biz_type`,
                    props: {
                      label: "dictValue",
                      value: "dictKey",
                    },
                  },
                ],
              },
            },
            on: {
              "reset-change": function ($event) {
                _vm.hdBox = false
              },
              submit: _vm.handlewtxz,
            },
            model: {
              value: _vm.wtxzForm,
              callback: function ($$v) {
                _vm.wtxzForm = $$v
              },
              expression: "wtxzForm",
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "人员选择",
            visible: _vm.deptShow,
            width: "80%",
            top: "8vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.deptShow = $event
            },
          },
        },
        [
          _vm.deptShow
            ? _c("ProjectSelectUser", {
                attrs: {
                  treeParams: {
                    parentId:
                      _vm.dataForm.projectId && _vm.dataForm.projectId != -1
                        ? _vm.dataForm.projectId
                        : _vm.dataForm.organizationId,
                  },
                },
                on: {
                  closeDia: function ($event) {
                    _vm.deptShow = false
                  },
                  "select-data": _vm.getUser,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
          attrs: {
            title: "附件预览",
            modal: true,
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            visible: _vm.showFileDialog,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showFileDialog = $event
            },
          },
        },
        [
          _c("iframe", {
            ref: "fileIframe",
            staticStyle: { width: "100%", height: "500px" },
            attrs: { src: _vm.fileUrl, frameborder: "0" },
          }),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.showFileDialog = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "项目选择", visible: _vm.deptShow1, width: "80%" },
          on: {
            "update:visible": function ($event) {
              _vm.deptShow1 = $event
            },
          },
        },
        [
          _c("DeptDialog", {
            ref: "DeptDialog",
            attrs: { deptCategory: [2, 5], isOnlyShowPrj: true },
            on: { "select-data": _vm.selectData1 },
          }),
        ],
        1
      ),
      _c("model", { ref: "model", attrs: { callback: _vm.handleClick } }),
      _c("sortSetting", { ref: "sortSetting", on: { callback: _vm.callback } }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "项目区域选择",
            visible: _vm.areaDialog,
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.areaDialog = $event
            },
          },
        },
        [
          _c("prjArea", {
            ref: "prjArea",
            attrs: { dialogType: "" },
            on: { prjAreaCallback: _vm.prjAreaCallback },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }