<template>
  <div style="height: 100%; background-color: #ffffff">
    <head-layout
      head-title="任务基本信息"
      :head-btn-options="headBtnOptions"
      @head-save="headSave(false)"
      @head-saveBack="headSave(true)"
      @head-forward="headForward('forward')"
      @head-cancel="headCancel"
    ></head-layout>
    <div class="formContentBox">
      <div class="formMain" style="padding: 0 12px">
        <el-form
          ref="dataForm"
          :model="dataForm"
          label-width="150px"
          :disabled="formType == 'view'"
        >
          <!-- <div class="formTopic" style="margin-bottom: 12px">检查基本信息</div> -->
          <el-row>
            <el-col :span="8">
              <el-form-item label="任务编号" prop="actCode">
                <el-input
                  :disabled="
                    dataForm.isAutomatic || pageDisabled || dataForm.id
                  "
                  v-model="dataForm.taskCode"
                  placeholder="请输入任务编号"
                >
                  <template slot="append">
                    自动生成
                    <el-switch
                      :disabled="pageDisabled || dataForm.id"
                      v-model="dataForm.isAutomatic"
                      active-color="#13ce66"
                      @change="handleSwitch"
                    >
                    </el-switch>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="检查名称" prop="actName">
                <el-input
                  v-model="dataForm.taskName"
                  :title="dataForm.taskName"
                  placeholder="请输入检查名称"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="检查类型" prop="checkType">
                <el-select
                  v-model="dataForm.checkType"
                  placeholder="请选择检查类型"
                >
                  <el-option
                    v-for="item in safey_check_type"
                    :key="item.dictKey"
                    :label="item.dictValue"
                    :value="item.dictKey"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="发起人" prop="startUserName">
                <el-input
                  @focus="headAdd1('startUserName')"
                  v-model="dataForm.startUserName"
                  placeholder="请填写发起人"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="所属项目" prop="organizationName">
                <el-input
                  @focus="handleProjectOPen()"
                  v-model="dataForm.organizationName"
                  :title="dataForm.organizationName"
                  placeholder="请填写所属项目"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="检查日期" prop="checkTime">
                <el-date-picker
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  v-model="dataForm.checkTime"
                  placeholder="请选择检查日期"
                ></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="备注" prop="remark">
                <el-input
                  v-model="dataForm.remark"
                  type="textarea"
                  :autosize="{ minRows: 2 }"
                  show-word-limit
                  placeholder="请填写备注"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="shuttleBackBox" v-if="dataForm.id">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane
              v-if="
                formType != 'add' &&
                (dataForm.addFrom == 0 || dataForm.addFrom == 3)
              "
              label="检查项"
              name="corePersonnel"
            ></el-tab-pane>
            <el-tab-pane
              v-if="formType != 'add' && dataForm.addFrom == 0"
              label="相关资料 "
              name="thirdPartyPersonnel"
            ></el-tab-pane>
            <el-tab-pane label="问题清单" name="taskList"></el-tab-pane>
          </el-tabs>
          <el-container
            class="main-box"
            v-if="activeName == 'corePersonnel' && formType != 'add'"
          >
            <CommonTree
              treeTitle="检查项分类"
              isLeafIcons
              node-key="id"
              ref="commonTreeCore"
              iconsFlied="isObject"
              :defaultExpandedKeys="defaultExpandedKeys"
              searchTitle="typeName"
              :treeData="corePersonnelTreeData"
              :defaultProps="{ children: 'children', label: 'typeName' }"
              @getNodeClick="corePersonnelTreeNodeClick"
              :showCheckbox="false"
              :treeExpand="false"
            />
            <div class="table-box">
              <head-layout head-title="检查项"></head-layout>
              <grid-layout
                ref="gridLayOut1"
                :table-options="corePersonnelTableOption"
                :data-total="page.total"
                :page="page"
                :cell-style="cellStyle"
                @page-current-change="getJcx"
                @page-size-change="getJcx"
                :table-data="corePersonnelTableData"
                :table-loading="jcxTableLoading"
                @gird-handle-select-click="selectionChange"
              >
                <template #customBtn="{ row }">
                  <el-button
                    v-if="formType != 'view'"
                    style="margin: 0 3px"
                    type="text"
                    size="small"
                    @click="
                      zxjc({
                        ...row,
                        disabled: false,
                      })
                    "
                    >{{
                      row.lineStatus == "PREPARE" ? "执行检查" : "执行检查"
                    }}</el-button
                  >
                  <el-button
                    v-if="formType == 'view'"
                    style="margin: 0 3px"
                    type="text"
                    size="small"
                    @click="
                      zxjc({
                        ...row,
                        disabled: true,
                      })
                    "
                    >检查结果</el-button
                  >
                </template>
              </grid-layout>
            </div>
          </el-container>
          <div v-if="activeName == 'thirdPartyPersonnel'">
            <head-layout head-title="资料清单"> </head-layout>
            <el-upload
              disabled
              :on-preview="handlePreview"
              :file-list="xgzlFileList"
              class="upload-demo"
            >
            </el-upload>
          </div>
          <div v-if="activeName == 'taskList' && dataForm.id">
            <head-layout
              head-title="问题清单"
              :head-btn-options="formType != 'view' ? tabsHeadBtnOptions : []"
              @head-remove="headRomve"
              @head-add="headAdd"
            ></head-layout>
            <grid-layout
              ref="gridLayOut"
              :table-options="hdchecktaskproOption"
              @grid-row-detail-click="rowView"
              :table-data="hdchecktaskproData"
              :table-loading="tableLoading"
              @gird-handle-select-click="selectionChange1"
            >
              <template #customBtn="{ row }">
                <el-button
                  style="margin: 0 3px"
                  type="text"
                  v-if="
                    row.hdStatus == 'prepare' &&
                    userInfo.user_id == row.createUser &&
                    dataForm.addFrom == '0'
                  "
                  size="small"
                  @click="rowEdit(row)"
                  >编辑</el-button
                >
                <el-button
                  style="margin: 0 3px"
                  type="text"
                  v-if="
                    row.hdStatus == 'prepare' &&
                    userInfo.user_id == row.createUser &&
                    dataForm.addFrom == '0'
                  "
                  size="small"
                  @click="rowDel([row])"
                  >删除</el-button
                >
                <el-button
                  style="margin: 0 3px"
                  type="text"
                  v-if="row.hdStatus != 'prepare'"
                  size="small"
                  @click="rowView(row)"
                  >查看</el-button
                >
              </template>
            </grid-layout>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :title="modelForm.lineStatus != 'PREPARE' ? '检查项查看' : '执行检查'"
      v-dialogDrag
      v-if="zxhcModel"
      :visible.sync="zxhcModel"
      class="avue-dialog avue-dialog--top"
      width="60%"
    >
      <el-form
        ref="modelFormDataForm"
        :model="modelForm"
        label-width="150px"
        :disabled="modelForm.disabled"
        :rules="rules"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="检查结果" prop="lineStatus">
              <el-radio
                v-model="modelForm.lineStatus"
                label="NORMAL"
                @input="handleInput"
                >正常</el-radio
              >
              <el-radio
                v-model="modelForm.lineStatus"
                label="ERROR"
                @input="handleInput"
                >异常</el-radio
              >
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="检查地点" prop="prjName">
              <el-input
                v-model="modelForm.position"
                placeholder="请填写检查地点"
              >
                <template slot="append">
                  <div
                    class="el-icon-map-location"
                    style="width: 54px; text-align: center"
                    @click="openMap()"
                  ></div>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="检查内容" prop="indexName">
              <div class="flex-container">
                <el-input
                  v-model="modelForm.indexName"
                  type="textarea"
                  :autosize="{ minRows: 2 }"
                  maxlength="500"
                  show-word-limit
                  placeholder="请输入检查内容"
                ></el-input>
                <div class="hdDesc-mar">
                  <el-button type="primary" @click="handleDesc()"
                    >常见隐患</el-button
                  >
                </div>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="modelForm.lineStatus == 'ERROR'">
            <el-form-item label="项目区域" prop="area">
              <el-input
                v-model="modelForm.area"
                ref="areaName"
                placeholder="请选择项目区域"
                :title="modelForm.area"
                @focus="handleChangeArea"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="modelForm.lineStatus == 'ERROR'">
            <el-form-item label="隐患接收人" prop="assessmentUser">
              <el-input
                v-model="modelForm.assessmentUserName"
                ref="assessmentUserName"
                disabled
                :title="modelForm.assessmentUserName"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="modelForm.lineStatus == 'ERROR'">
            <el-form-item label="隐患编号" prop="hdCode">
              <el-input
                v-model="modelForm.hdCode"
                disabled
                placeholder="隐患编号自动生成"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="隐患描述"
              prop="hdDesc"
              v-if="modelForm.lineStatus == 'ERROR'"
            >
              <el-input
                type="textarea"
                :autosize="{ minRows: 2 }"
                show-word-limit
                v-model="modelForm.hdDesc"
                maxlength="225"
                placeholder="请填写隐患描述"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="modelForm.lineStatus == 'ERROR' ? '隐患情况' : '现场情况'"
              prop="prjAs"
            >
              <el-upload
                v-if="formType != 'view'"
                accept=".jpg,.mp4,.png"
                style="margin-bottom: 12px; display: flex; align-items: center"
                class="upload-demo"
                action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz-thumbnail/km"
                :show-file-list="false"
                :before-upload="checkFileType"
                :on-success="handleAvatarSuccess"
                ref="uploadRef"
                :headers="headers"
              >
                <el-button size="small" type="primary" icon="el-icon-upload"
                  >点击上传
                </el-button>
              </el-upload>
              <div class="content">
                <div
                  v-for="(item, index) in treeData"
                  :key="item.id"
                  class="img-content"
                  @click="handlePreview(item)"
                >
                  <img :src="item.thumbnailLink" alt="" />
                  <i
                    v-if="formType !== 'view'"
                    class="el-icon-circle-close delete-icon"
                    @click.stop="handleRemove(item, index)"
                  ></i>
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="avue-dialog__footer">
        <el-button
          :loading="handleBtn"
          :disabled="handleBtn"
          @click="zxhcModel = false"
          >取 消</el-button
        >
        <el-button
          v-if="!modelForm.disabled"
          :loading="handleBtn"
          :disabled="handleBtn"
          @click="handleZxhc"
          type="primary"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog title="选择位置" append-to-body :visible.sync="mapBox">
      <GaodeMap
        v-if="mapBox"
        ref="GaodeMap"
        @selectAddress="selectAddress"
        @getLocation="getLocation"
      ></GaodeMap>
      <span slot="footer">
        <el-button size="small" @click="mapBox = false">
          {{ $t(`cip.cmn.btn.celBtn`) }}
        </el-button>
        <el-button size="small" type="primary" @click="handleMapSave">
          {{ $t(`cip.cmn.btn.defBtn`) }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="问题新增"
      v-dialogDrag
      append-to-body
      :visible.sync="hdBox"
      width="400px"
    >
      <avue-form
        @reset-change="hdBox = false"
        v-model="wtxzForm"
        @submit="handlewtxz"
        :option="{
          emptyText: '取消',
          column: [
            {
              label: '类型',
              prop: 'proType',
              span: 24,
              type: 'radio',
              rules: [
                {
                  required: true,
                  message: '请选择类型',
                  trigger: 'blur',
                },
              ],
              dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=hd_biz_type`,
              props: {
                label: 'dictValue',
                value: 'dictKey',
              },
            },
          ],
        }"
      ></avue-form>
    </el-dialog>
    <el-dialog title="人员选择" :visible.sync="deptShow" width="80%" top="8vh">
      <!-- <UserDetpDialog
        ref="UserDetpDialog"
        :dept-category="type == 'startUserName' ? [2, 5] : []"
        @select-data="selectData"
      ></UserDetpDialog> -->
      <ProjectSelectUser @closeDia="deptShow=false" :treeParams="{parentId: dataForm.projectId && dataForm.projectId != -1 ? dataForm.projectId : dataForm.organizationId}" v-if="deptShow" @select-data="getUser"></ProjectSelectUser>
    </el-dialog>
    <el-dialog
      v-dialog-drag
      title="附件预览"
      :modal="true"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="showFileDialog"
      width="70%"
    >
      <iframe
        ref="fileIframe"
        :src="fileUrl"
        frameborder="0"
        style="width: 100%; height: 500px"
      ></iframe>
      <span slot="footer">
        <el-button size="small" @click="showFileDialog = false">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="项目选择" :visible.sync="deptShow1" width="80%">
      <DeptDialog
        ref="DeptDialog"
        :deptCategory="[2, 5]"
        @select-data="selectData1"
        :isOnlyShowPrj="true"
      ></DeptDialog>
    </el-dialog>
    <model ref="model" :callback="handleClick"></model>
    <sortSetting ref="sortSetting" @callback="callback"></sortSetting>
    <el-dialog title="项目区域选择" :visible.sync="areaDialog" width="80%">
      <prjArea ref="prjArea" @prjAreaCallback="prjAreaCallback" dialogType />
    </el-dialog>
  </div>
</template>
<script>
import CommonTree from "@/views/components/com_tree/index";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import UserDetpDialog from "@/views/components/UserDeptDialog/UserDetpDialog.vue";
import ProjectSelectUser from "@/views/components/UserDeptDialog/projectSelectUser";
import { getToken } from "@/util/auth";
import { dictionaryBiz } from "@/api/reportTasks";
import prjArea from "@/views/business/safe/itemList/projectArea/index.vue";
import { mapGetters } from "vuex";
import DeptDialog from "@/views/components/UserDeptDialog/DeptDialog.vue";
import * as API from "@/api/check/checkTasks";
import {
  getTreeTypes,
  hdcheckmaterialList,
  hdchecktaskproPage,
  hdchecktaskproRemove,
} from "@/api/check/daily";
import website from "@/config/website";
import GaodeMap from "@/views/system/attendance/GaodeMap.vue";
import { dateFormat } from "@/util/date";
import Template from "@/views/message/template/list.vue";
import model from "./model.vue";
import sortSetting from "@/views/business/safetyTtandard/sortSetting/model.vue";
import { roleIds, getUsersByRoleIdAndDeptId } from "@/api/system/client";

export default {
  components: {
    sortSetting,
    Template,
    model,
    GaodeMap,
    DeptDialog,
    UserDetpDialog,
    CommonTree,
    GridLayout,
    HeadLayout,
    prjArea,
    ProjectSelectUser
  },
  data() {
    return {
      fileUrl: "",
      type: "",
      defaultExpandedKeys: [],
      deptShow: false,
      deptShow1: false,
      showFileDialog: false,
      tabsHeadBtnOptions: [
        {
          label: "新增",
          emit: "head-add",
          type: "button",
          icon: "",
          btnOptType: "primary",
        },
        {
          label: this.$t("cip.cmn.btn.delBtn"),
          emit: "head-remove",
          type: "button",
          icon: "",
        },
      ],
      rules: {
        hdDesc: [
          {
            required: true,
            message: "请输入隐患描述",
            trigger: "blur",
          },
        ],
        lineStatus: [
          {
            required: true,
            message: "请选择隐患结果",
            trigger: "blur",
          },
        ],
        area: [
          {
            required: true,
            message: "项目区域不能为空",
            trigger: "change",
          },
        ],
        assessmentUser: [
          {
            required: true,
            message: "请配置隐患接收人",
          },
        ],
      },
      mapdata: {
        longitude: "116.481752",
        latitude: "40.01681",
      },
      modelForm: {
        hdDesc: "",
      },
      wtxzForm: {},
      mapBox: false,
      hdBox: false,
      zxhcModel: false,
      handleBtn: false,
      tableLoading: false,
      jcxTableLoading: false,
      thirdPartyPersonnelTableData: [],
      corePersonnelTableData: [],
      corePersonnelTableOption: {
        index: true,
        indexLabel: "序号",
        menuWidth: 100,
        column: [
          {
            label: "检查内容",
            prop: "indexName",
            align: "left",
            overHidden: true,
          },
          {
            label: "检查结果",
            prop: "lineStatus",
            width: 200,
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=HD_CHECK_INDEX_STATUS`,
            props: {
              label: "dictValue",
              value: "dictKey",
            },

            type: "select",
            align: "center",
            overHidden: true,
          },
        ],
      },
      hdchecktaskproOption: {
        index: true,
        linklabel: "hdCode",
        
        indexLabel: "序号",
        menuWidth: 100,
        column: [
          {
            label: "问题编号",
            prop: "hdCode",
            align: "left",
            overHidden: true,
          },
          {
            label: "问题描述",
            prop: "hdDesc",
            width: 200,
            align: "center",
            overHidden: true,
          },
          {
            label: "问题类型",
            prop: "proType",
            width: 200,
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=hd_biz_type`,
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            type: "select",
            align: "center",
            overHidden: true,
          },
          {
            label: "上报人",
            prop: "reportUserName",
            width: 200,
            align: "center",
            overHidden: true,
          },
          {
            label: "上报人部门",
            prop: "reportDeptName",
            width: 200,
            align: "center",
            overHidden: true,
          },
          {
            label: "上报时间",
            prop: "reportDate",
            width: 200,
            align: "center",
            overHidden: true,
          },
          {
            label: "状态",
            prop: "hdStatus",
            type: "select",
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=hd_status`,
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            width: 200,
            align: "center",
            overHidden: true,
          },
        ],
      },
      activeName: "corePersonnel",
      treeData: [],
      corePersonnelTreeData: [],
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      selectTree: {},
      safey_check_type: [],
      hd_biz_type: [],
      xgzlFileList: [],
      hdchecktaskproData: [],
      selectionList1: [],
      selectionList: [],
      formType: "",
      dataForm: {
        isAutomatic: true,
      },
      lineStatusColor: {
        PREPARE: "rgb(56, 148, 255)",
        NORMAL: "rgb(122, 199, 86)",
        ERROR: "rgb(242, 97, 97)",
      },
      parentActiveName: "",
      areaDialog: false,
      deptShowType: ''
    };
  },
  computed: {
    pageDisabled() {
      // 如果查看 或者 提交状态则禁用
      if (this.formType == "view" || this.dataForm.actStatus == "PREPARE") {
        return true;
      } else {
        return false;
      }
    },
    headers: function () {
      return { "Sinoma-Auth": getToken() };
    },
    ...mapGetters(["userInfo"]),
    headBtnOptions() {
      let result = [];
      if (this.formType != "view" && this.dataForm.taskStatus != "FINISHED") {
        result = [
          {
            label: "保存",
            emit: "head-save",
            type: "button",
            btnOptType: "save",
          },
          {
            label: "提交并返回",
            emit: "head-saveBack",
            type: "button",
            btnOptType: "refer",
          },
        ];
      }
      if (this.formType != "view" && this.dataForm.taskStatus == "UNFINISHED") {
        result.push({
          label: "转发",
          emit: "head-forward",
          type: "button",
          icon: "",
          btnOptType: "forward",
        });
      }
      result.push({
        label: "取消",
        emit: "head-cancel",
        type: "button",
        btnOptType: "cancel",
      });
      return result;
    },
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    proId() {
      let ids = [];
      this.selectionList1.forEach((ele) => {
        ids.push(ele.proId);
      });
      return ids.join(",");
    },
  },
  async mounted() {
    this.formType = this.$route.query.type;
    this.dataForm.id = this.$route.query.id;
    this.dataForm.actId = this.$route.query.actId;
    this.parentActiveName = this.$route.query.activeName || "";
    if (this.formType == "edit" || this.formType == "view") {
      await this.getPrjbasicinfoDetail();
      if (this.dataForm.addFrom == 1) {
        this.activeName = "taskList";
        this.handleClick();
      }
    } else {
      // this.$set(this.dataForm, "organizationId", this.userInfo.dept_id);
      // this.$set(this.dataForm, "organizationName", this.userInfo.deptName);
      this.$set(this.dataForm, "organizationId", this.$route.query.organizationId);
      this.$set(this.dataForm, "organizationName", this.$route.query.organizationName);

      // this.$set(this.dataForm, "startUserId", this.userInfo.user_id);
      // this.$set(this.dataForm, "startUserName", this.userInfo.real_name);
      this.$set(this.dataForm, "respUserName", this.userInfo.account);
      this.$set(this.dataForm, "userName", this.userInfo.account);
      this.activeName = "taskList";
      this.getPageCode();
    }
    // check_type -> safey_check_type
    dictionaryBiz("safey_check_type").then((res) => {
      this.safey_check_type = res.data.data;
    });
    // HD_PRO_TYPE -> hd_biz_type
    dictionaryBiz("hd_biz_type").then((res) => {
      this.hd_biz_type = res.data.data;
    });
  },
  methods: {
    prjAreaCallback(data) {
      this.$set(this.modelForm, "areaId", data.id);
      this.$set(this.modelForm, "areaCode", data.areaCode);
      this.$set(this.modelForm, "area", data.areaName);
      this.areaDialog = false;
    },
    handleDesc() {
      this.$refs.sortSetting.initTree();
    },
    callback(val) {
      this.$set(this.modelForm, "indexName", val.content);
    },
    handleProjectOPen() {
      this.deptShow1 = true;
    },
    headAdd1(type) {
      this.type = type;
      if(type=='startUserName'&&!this.dataForm.organizationId){
       this.$message({
          message: "请选择所属项目",
          type: "warning",
        })
        return
      }
      this.deptShow = true;
    },
    selectData1(row) {
      this.deptShow1 = false;
      this.$set(this.dataForm, "organizationId", row.id);
      this.$set(this.dataForm, "organizationName", row.title);
      this.$set(this.dataForm, "deptCategory", row.deptCategory);
      console.log("进行输出人员-------------------", row);
    },

    getUser(data){
      // console.log(data,this.type);
      if (this.type == "startUserName") {
        this.$set(this.dataForm, "startUserId", data.userId);
        this.$set(this.dataForm, "startUserName", data.userName);
      }  else {
        API.submit({
          ...this.dataForm,
          respNickName: data.userName,
          userName: data.account,
        }).then((res) => {
          this.$message.success("转发成功");
          this.getPrjbasicinfoDetail();
        });
      }
      this.deptShow = false;
    },

    selectData(data) {
      console.log(data,this.type);
      if (this.type == "startUserName") {
        this.$set(this.dataForm, "startUserId", data.id);
        this.$set(this.dataForm, "startUserName", data.realName);
      } else {
        API.submit({
          ...this.dataForm,
          respNickName: data.realName,
          userName: data.account,
        }).then((res) => {
          this.$message.success("转发成功");
          this.getPrjbasicinfoDetail();
        });
      }
      this.deptShow = false;
    },
    async getPageCode() {
      let code = await API.getCode().then((res) => res.data.data);
      this.$set(this.dataForm, "taskCode", code);
    },
    handleSwitch() {
      if (this.dataForm.isAutomatic) {
        this.getPageCode();
      } else {
        this.dataForm.taskCode = "";
      }
    },
    handleInput() {
      if (this.modelForm.lineStatus != "ERROR") {
        this.modelForm.hdDesc = "";
        this.treeData = [];
      } else {
        roleIds({
          roleNames: "隐患接收人",
          tenantId: this.userInfo.tenant_id,
        }).then((res) => {
          if (res.data.code == 200) {
            let roleId = res.data.data;
            if (roleId && typeof roleId == "string") {
              getUsersByRoleIdAndDeptId({
                deptId: this.dataForm.organizationId,
                roleId,
                needChildren: "yes",
              }).then((result) => {
                let data = result.data.data;
                this.modelForm.assessmentDept = this.dataForm.organizationId;
                if (typeof data === "object" && Array.isArray(data)) {
                  let assessmentUser = data.map((val) => val.id).toString();
                  this.$set(this.modelForm, "assessmentUser", assessmentUser);
                  let assessmentUserName = data
                    .map((val) => val.realName)
                    .toString();
                  this.$set(
                    this.modelForm,
                    "assessmentUserName",
                    assessmentUserName
                  );
                } else {
                  this.$message.warning("请设置该项目隐患接收人");
                }
              });
            }
          } else {
            this.$message.warning("系统未设置隐患接收人，请联系管理员");
          }
        });
      }
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (column.property == "lineStatus") {
        return {
          color: this.lineStatusColor[row.lineStatus],
        };
      }
    },

    headSave(boolean) {
      API.submit({
        ...this.dataForm,
        actId: this.dataForm.actId ? this.dataForm.actId : 0,
        addFrom: this.dataForm.addFrom
          ? this.dataForm.addFrom
          : this.formType == "add"
          ? "1"
          : "0",
        taskStatus: boolean ? "FINISHED" : "UNFINISHED",
      }).then((res) => {
        if (boolean) {
          this.$router.$avueRouter.closeTag();
          this.$router.back();
        } else {
          this.dataForm.id = res.data.data;
          this.$message.success(res.data.msg);
          this.getPrjbasicinfoDetail();
        }
      });
    },
    headForward(type) {
      this.type = type;
      this.deptShowType = type
      this.deptShow = true;
    },
    handleChangeArea() {
      this.$refs.areaName.blur();
      if (!this.dataForm.securityInspectionId) {
        if (this.dataForm.organizationId) {
          this.areaDialog = true;
          this.$nextTick(() => {
            this.$refs.prjArea.init(this.dataForm.organizationId, "view");
          });
        } else {
          this.$message.warning("请先选择项目");
        }
      }
    },
    handlewtxz() {
      this.$router.push({
        path: `/hiddenTrouble/riskitemView/checklist/edit`,
        query: {
          taskId: this.dataForm.id,
          type: "add",
          proType: this.wtxzForm.proType,
        },
      });
    },
    // 编辑
    rowEdit(row) {
      this.$router.push({
        path: `/hiddenTrouble/riskitemView/checklist/edit`,
        query: {
          id: row.id,
          type: "edit",
        },
      });
    },
    rowView(row) {
      this.$router.push({
        path: `/hiddenTrouble/riskitemView/checklist/edit`,
        query: {
          id: row.id,
          type: "view",
        },
      });
    },
    headRomve() {
      this.rowDel(this.selectionList1);
    },
    rowDel(row) {
      this.selectionList1 = row;
      if (this.selectionList1.length === 0) {
        this.$message.warning(this.$t("cip.cmn.msg.warning.selectWarning"));
        return;
      }
      this.$confirm(this.$t("cip.cmn.msg.warning.determineDelWarning"), {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let formData = new FormData();
          formData.append("ids", this.proId);
          return hdchecktaskproRemove(formData);
        })
        .then(() => {
          this.handleClick();
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
    },
    headAdd() {
      if (this.dataForm.addFrom == 1) {
        this.$refs.model.initData({
          taskId: this.dataForm.id,
        });
      } else {
        this.$router.push({
          path: `/hiddenTrouble/riskitemView/checklist/edit`,
          query: {
            taskId: this.dataForm.id,
            type: "add",
            proType: 1,
          },
        });
        // this.hdBox = true;
      }
    },
    async zxjc(row) {
      let lineStatus = row.lineStatus == "PREPARE" ? "" : row.lineStatus;
      await API.hdchecktasklineDetail({ id: row.id }).then((res) => {
        let data = res.data.data;
        this.modelForm = {
          ...data,
          yhID: data.hdHiddenDanger.id,
          ...data.hdHiddenDanger,
          id: data.id,
          disabled: row.disabled,
          lineStatus: lineStatus || "NORMAL",
          taskId: this.dataForm.id,
          indexTypeId: this.selectTree.id,
          indexTypeName: this.selectTree.typeName,
        };
        if (lineStatus == "ERROR") {
          this.treeData = data.hdHiddenDanger.hdUrl
            ? JSON.parse(data.hdHiddenDanger.hdUrl)
            : [];
        } else {
          this.treeData = data.scenePhoto ? JSON.parse(data.scenePhoto) : [];
        }
        this.zxhcModel = true;
      });
    },
    handleZxhc() {
      this.$refs.modelFormDataForm.validate((valid) => {
        if (valid) {
          this.handleBtn = true;
          console.log(this.modelForm.assessmentUserName, "assessmentUserName");
          API.hdchecktasklineUpdate({
            ...this.modelForm,
            scenePhoto: JSON.stringify(this.treeData),
            hdHiddenDanger: {
              id: this.modelForm.yhID,
              location: this.modelForm.position,
              latitude: this.modelForm.latitude,
              reportDate: dateFormat(new Date()),
              organizationId: this.userInfo.dept_id,
              organizationName: this.userInfo.deptName,
              reportDeptId: this.userInfo.dept_id,
              reportDeptName: this.userInfo.deptName,
              reportUser: this.userInfo.user_id,
              reportUserName: this.userInfo.real_name,
              hdUrl: JSON.stringify(this.treeData),
              hdDesc: this.modelForm.hdDesc,
              areaCode: this.modelForm.areaCode,
              areaId: this.modelForm.areaId,
              area: this.modelForm.area,
              assessmentDept: this.modelForm.assessmentDept,
              assessmentUser: this.modelForm.assessmentUser,
              assessmentUserName: this.modelForm.assessmentUserName,
            },
          })
            .then((res) => {
              this.$message.success(res.data.msg);
              this.zxhcModel = false;
              this.handleBtn = false;
              this.getJcx();
            })
            .catch((err) => {
              this.handleBtn = false;
              this.$message.error(err);
              this.zxhcModel = false;
            });
        }
      });
    },
    // 高德地图用
    selectAddress(data) {
      this.$set(this.modelForm, "position", data.address);
      this.$set(this.dataForm, "latitude", data.lng + "," + data.lat);
    },
    // 高德地图用
    getLocation(data) {
      this.$set(this.modelForm, "position", data.address);
      this.$set(this.dataForm, "latitude", data.lng + "," + data.lat);
    },
    handleMapSave() {
      this.mapBox = false;
    },
    //获取经纬度
    openMap() {
      this.mapBox = true;
    },
    typeTree() {
      let obj = {};
      if (this.dataForm.actId && this.dataForm.actId != -1) {
        obj.actId = this.dataForm.actId;
      } else {
        obj.taskId = this.dataForm.id;
      }
      API.getTreeTypes(obj).then((res) => {
        this.corePersonnelTreeData = res.data.data;
        this.selectTree = res.data.data[0]?.children[0] || {};
        if (!this.selectTree.id) return;
        this.defaultExpandedKeys = [this.selectTree.id];
        this.$nextTick(() => {
          this.$refs.commonTreeCore.$refs.commonTree.setCurrentKey(
            this.selectTree.id
          );
          this.$refs.commonTreeCore.nodeData = this.selectTree;
          this.corePersonnelTreeNodeClick(this.selectTree);
        });
      });
    },
    handleRemove(item, index) {
      this.treeData.splice(index, 1);
    },
    handleAvatarSuccess(res, file, fileList) {
      this.treeData.push({
        extension: file.raw.type,
        id: file.response.data.attachId,
        link: file.response.data.link,
        thumbnailLink: file.response.data.thumbnailLink,
        name: file.response.data.originalName,
      });
    },
    checkFileType(file) {
      const fileType = file.name.substring(file.name.lastIndexOf("."));
      const isLt20M = file.size / 1024 / 1024 < 20;
      const isJPG =
        fileType === ".jpg" || fileType == ".png" || fileType == ".mp4";

      if (!isJPG) {
        this.$message.warning("上传图片只能是 .jpg  .png .mp4 格式!");
      }
      if (!isLt20M) {
        this.$message.warning("上传文件大小不能超过 20MB!");
      }
      return isJPG && isLt20M;
    },
    getJcx(page) {
      this.page = page || this.page;
      this.jcxTableLoading = true;
      API.hdchecktasklineList({
        current: this.page.currentPage,
        size: this.page.pageSize,
        taskId: this.dataForm.id,
        objectId: this.selectTree.id,
        objectName: this.selectTree.typeName,
      }).then((res) => {
        this.page.total = res.data.data.total;
        this.corePersonnelTableData = res.data.data.records;
        // this.$refs.gridLayOut1.page.total = res.data.data.total;
        this.jcxTableLoading = false;
      });
    },

    handleClick() {
      if (this.activeName == "corePersonnel") {
        this.typeTree();
      } else if (this.activeName == "thirdPartyPersonnel") {
        this.getFile();
      } else if (this.activeName == "taskList") {
        hdchecktaskproPage({
          taskId: this.dataForm.id,
        }).then((res) => {
          this.hdchecktaskproData = res.data.data.records;
        });
      }
    },
    corePersonnelTreeNodeClick(row) {
      this.selectTree = row;
      this.getJcx();
    },
    //批量选择
    selectionChange(list) {
      this.selectionList = list;
    }, //批量选择
    selectionChange1(list) {
      this.selectionList1 = list;
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    // 根据ID查表单详情
    async getPrjbasicinfoDetail() {
      await API.detail({
        id: this.dataForm.id,
      }).then((res) => {
        if (res.data.code == 200) {
          this.dataForm = res.data.data;
        }
      });
      this.typeTree();
      this.getJcx();
    },
    handlePreview(item) {
      console.log(item, "handlePreview");
      this.fileUrl =
        process.env.VUE_APP_BASE_KKFILEURL +
        "?url=" +
        encodeURIComponent(Base64.encode(item.link));
      this.showFileDialog = true;
      //exls表格预览隐藏打印按钮
      if (process.env.NODE_ENV === "production") {
        const iframe = this.$refs.fileIframe;
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;

        // 在 iframe 内部隐藏元素
        if (iframeDocument) {
          const elementToHide = iframeDocument.getElementById("button-area");
          if (elementToHide) {
            elementToHide.style.display = "none";
          }
        }
      }
    },
    getFile() {
      hdcheckmaterialList({
        current: 1,
        pages: 999,
        actId: this.dataForm.actId,
      }).then((res) => {
        this.xgzlFileList = res.data.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .common_tree_handle {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.formContentBox {
  padding: 12px;
  height: calc(100% - 76px);
}

.formMain {
  width: 100%;
  height: 100%;
}

.formTopic {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid rgb(187, 187, 187);
  font-weight: 600 !important;
  font-size: 18px;
}

.shuttleBackBox {
  width: 100%;
  height: calc(100% - 160px);

  .main-box {
    height: calc(100% - 56px);
    overflow: scroll;
  }

  .el-tabs {
    width: 100%;
  }

  .upload-demo {
    background-color: #ffffff;
    padding-left: 10px;
  }

  .table-box {
    height: 100%;
    //overflow: scroll;
    width: calc(100% - 280px);
  }
}

.shutleBack {
  width: 33%;
  background: #ffffff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
}

.leftRightBtn {
  margin: 211px 12px;
}

.shutleTitle {
  width: 100%;
  background-color: #f5f7fa;
  height: 40px;
  line-height: 40px;
  text-indent: 12px;
  border-bottom: 1px solid #ebeef5;
  font-size: 16px;
}

.shutContent {
  padding: 6px;
  height: 400px;
  overflow: auto;
}

.deptBox {
  line-height: 40px;
  color: #333333;
  border-bottom: 1px solid #ebeef5;
  font-size: 13px;
  display: flex;
}

.unitBox {
  width: 100%;
  display: flex;
  background-color: #f5f7fa;
  border-bottom: 1px solid #ebeef5;
}

.unitTitle {
  height: 40px;
  line-height: 40px;
  padding: 0 12px;
  font-size: 16px;
  cursor: pointer;
  border-right: 1px solid #ebeef5;
}

.content {
  display: flex;
  align-items: center;

  .img-content {
    width: 188px;
    height: 100px;
    padding: 3px;
    position: relative;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-right: 12px;

    .delete-icon {
      position: absolute;
      top: -6px;
      right: -6px;
      font-size: 16px;
      color: red;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
