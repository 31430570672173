<template>
  <avue-tree :option="option"
             :data="data"
             ref="avueTree"
             @node-click="handleNodeClick"></avue-tree>
</template>

<script>
import {tree, treeByParentId} from '@/api/plugin/workflow/category';

export default {
  name: 'wf-category',
  props: {
    parentId: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      data: [],
      option: {
        size: 'mini',
        menu: false,
        addBtn: false,
        props: {
          label: 'name',
          value: 'id'
        }
      }
    }
  },
  mounted() {
    this.$refs.avueTree.$children[0].$refs.input.placeholder = this.$t('cip.cmn.msg.warning.filterateWarning');
    this.getCategoryList()
  },
  methods: {
    handleNodeClick({ id }) {
      this.$emit('node-click', { id })
    },
    getCategoryList() {
      treeByParentId(this.parentId).then(res => {
        const data = res.data.data
        this.$emit('list-change', this.deepClone(data))
        this.data = data
      })
    },
  }
}
</script>

<style scoped>
  .avue-tree,.el-tree{
    background: #ffffff;
    border-right:1px solid #cccccc;
  }

</style>
